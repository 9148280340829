import {Component, Inject} from '@angular/core'
import {WorkBook, WorkSheet} from 'xlsx'

import {LoanListItem} from '../../services/data.service'
import {XLSX_PROVIDER} from '../../application/xlsx.provider'
import {FilterService} from '../../services/filter.service'
import {first} from 'rxjs/operators'

@Component({
  selector: 'spb-xls-export',
  templateUrl: './xls-export.component.html'
})
export class XlsExportComponent {

  public loanList: LoanListItem[] = []

  constructor(
    private filterService: FilterService,
    @Inject(XLSX_PROVIDER) private xlsx: any
  ) {
  }

  public doExport(): void {
    this.filterService.filteredLoans$
      .pipe(first())
      .subscribe({
        next: (loans: LoanListItem[]) => {
          this.createExport(loans)
        }
      })
  }

  private createExport(loans: LoanListItem[]): void {
    const wb: WorkBook = this.xlsx.utils.book_new()
    const rows: any = [['Kontor', 'Ansvarig', 'Namn', 'Person-/organisationsnummer', 'Lån',
      'Bindningstid', 'Omsättningsdatum',
      'Startdatum', 'Belopp', 'Levande', 'Ränta', 'Amortering', 'Nästa amortering',
      'Nästa ffd', 'Typ av brev', 'Ränteavvikelse', 'Produktkod']]
    // Check if no item is selected, i none selected print the full selection
    const none = loans.every(l => !l.selected)
    loans
      .filter(l => l.selected || none)
      .forEach(l => {
        rows.push([
          l.office,
          l.responsible,
          l.owner,
          l.id,
          l.loanNumber,
          l.frequency,
          new Date(l.renewalDate).toLocaleDateString('sv-SE'),
          new Date(l.start).toLocaleDateString('sv-SE'),
          l.amount,
          l.alive ? 'Nej' : 'Ja',
          l.interest,
          l.repaymentAmount,
          new Date(l.repaymentDate).toLocaleDateString('sv-SE'),
          new Date(l.interestDate).toLocaleDateString('sv-SE'),
          l.type,
          l.interestDeviation,
          l.productCode
        ])
      })
    const ws: WorkSheet = this.xlsx.utils.aoa_to_sheet(rows)
    this.xlsx.utils.book_append_sheet(wb, ws, 'lån')
    this.xlsx.writeFile(wb, `lån.xlsx`)
  }
}
