<div class="header">
  <spb-logo title="Omsättningsverktyg"></spb-logo>
  <div class="filler"></div>
  <div *ngIf="configService.configState$ | async as config" class="name">
    {{ config.user?.name }}
    <button *ngIf="config.ready" [matMenuTriggerFor]="menu" mat-button>
      <span class="material-symbols-outlined">menu</span>
    </button>
    <mat-menu #menu="matMenu">
      <button [routerLink]="['main']" mat-menu-item routerLinkActive="selected">Omsättningar</button>
      <button [routerLink]="['shadow']" mat-menu-item routerLinkActive="selected">Bostadslån</button>
      <button
        *ngIf="config.bindings"
        [routerLink]="['bindings']" mat-menu-item routerLinkActive="selected">Ombindningar
      </button>
      <button (click)="filterService.resetFilter()" mat-menu-item>Återställ filter</button>
      <button (click)="configService.logout()" mat-menu-item>Logga ut</button>
    </mat-menu>
  </div>
</div>
