import {Pipe, PipeTransform} from '@angular/core'

@Pipe({
  name: 'sdcDate'
})
export class SdcDatePipe implements PipeTransform {

  transform(value: string): number {
    return new Date(value).getTime()
  }
}
