import {Component} from '@angular/core'
import {ConfigService} from '../../services/config.service'
import {FilterService} from '../../services/filter.service'

@Component({
  selector: 'spb-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {
  /**
   * The constructor
   *
   * @param configService - Holds the logged in state and tells ut what to show.
   * @param filterService - So that we can reset the filter.
   */
  constructor(
    public configService: ConfigService,
    public filterService: FilterService,
  ) {
  }
}
