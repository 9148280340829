import {NgModule} from '@angular/core'
import {CommonModule} from '@angular/common'
import {HeaderComponent} from './header/header.component'
import {ReactiveFormsModule} from '@angular/forms'
import {MatDialogModule} from '@angular/material/dialog'
import {MatButtonModule} from '@angular/material/button'
import {MatMenuModule} from '@angular/material/menu'
import {MatIconModule} from '@angular/material/icon'
import {MatFormFieldModule} from '@angular/material/form-field'
import {MatInputModule} from '@angular/material/input'
import {RouterModule} from '@angular/router'
import {XlsExportComponent} from './xls-export/xls-export.component'
import {SdcDatePipe} from './pipes/sdc-date.pipe'
import {NoDataComponent} from './no-data/no-data.component'
import {ThemeModule} from '@sparbanken-syd/sparbanken-syd-theme'

@NgModule({
  declarations: [
    HeaderComponent,
    XlsExportComponent,
    SdcDatePipe,
    NoDataComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatDialogModule,
    MatButtonModule,
    MatMenuModule,
    MatIconModule,
    RouterModule,
    ThemeModule
  ],
  exports: [
    HeaderComponent,
    XlsExportComponent,
    SdcDatePipe
  ]
})
export class SpbCommonModule {
}
