import {Inject, Injectable, signal, WritableSignal} from '@angular/core'
import {DataService, LoanListItem} from './data.service'
import {BehaviorSubject} from 'rxjs'
import {LOCAL_STORAGE} from '../application/localstorage.provider'
import {Sort} from '@angular/material/sort'
import {DateTime} from 'luxon'

export const FILTER_VERSION = 1

export const getEmptyFilter = (): FilterData => {
  return {
    version: FILTER_VERSION,
    changed: false,
    letter: true,
    office: '',
    employee: '',
    productCode: 'all',
    bound: true,
    unbound: true,
    private: true,
    business: true,
    searchField: '',
    fromDate: null,
    toDate: null,
    sort: {active: '', direction: ''}
  }
}

export interface FilterData {
  /**
   * Used to know if filter needs reset
   */
  version: number
  changed: boolean
  letter: boolean
  office: string
  employee: string
  productCode: string
  bound: boolean
  unbound: boolean
  business: boolean
  private: boolean
  searchField: string
  fromDate: DateTime | null
  toDate: DateTime | null
  sort: Sort
}

const FILTER_NAME = 'filter'

@Injectable({
  providedIn: 'root'
})
/**
 * The point of this was to filter the list of loans
 */
export class FilterService {

  public filteredLoans$: BehaviorSubject<LoanListItem[]> = new BehaviorSubject<LoanListItem[]>([])

  public officeList: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([])

  public employeeList: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([])

  public productCodeList = new BehaviorSubject<string[]>([])

  public fieldList: string[] = []

  public filter: WritableSignal<FilterData> = signal(getEmptyFilter())

  private letterTypes: string[] = ['V', 'O']

  private ownerTypes: string[] = ['B', 'P']

  private bindings: string[] = ['3 må', '1 år', '2 år', '3 år', '4 år', '5 år', '7 år', '10 år']

  private loans: LoanListItem[] = []

  private pFilter: FilterData = getEmptyFilter()

  constructor(
    private dataService: DataService,
    @Inject(LOCAL_STORAGE) private injectedLocalStorage: Storage
  ) {
    this.dataService.loans$.subscribe((loans: LoanListItem[]) => {
      this.loans = loans
      this.officeList.next(this.getSet('office'))
      this.employeeList.next(this.getSet('responsible'))
      this.productCodeList.next(this.getSet('productCode'))
      // Load filter _after_ the loans so that it does not return empty
      this.loadFilter()
    })
  }

  public setFilters(input: Partial<FilterData>): FilterData {
    this.pFilter = Object.assign(this.pFilter, input)
    this.letterTypes = []
    this.ownerTypes = []
    this.bindings = []

    if (this.pFilter.changed) {
      this.letterTypes.push('V')
    }
    if (this.pFilter.letter) {
      this.letterTypes.push('O')
    }

    if (this.pFilter.bound) {
      this.bindings = ['1 år', '2 år', '3 år', '4 år', '5 år', '7 år', '10 år']
    }

    if (this.pFilter.unbound) {
      this.bindings.push('3 må')
    }

    if (this.pFilter.business) {
      this.ownerTypes.push('B')
    }

    if (this.pFilter.private) {
      this.ownerTypes.push('P')
    }

    // Filter the loans and save the filter
    this.filteredLoans$.next(this.applyFilters(this.pFilter))
    this.injectedLocalStorage.setItem(FILTER_NAME, JSON.stringify(this.pFilter))
    return this.pFilter
  }

  public resetFilter(): void {
    this.injectedLocalStorage.removeItem(FILTER_NAME)
    const data = this.setFilters(getEmptyFilter())
    this.filteredLoans$.next(this.applyFilters(data))
    // When we reset, we also rest our signal
    this.filter.set(data)
  }

  private loadFilter(): void {
    try {
      // Let all know we have a filter, it is either our empty filter or the one we find in local storage
      let filter: any = JSON.parse(this.injectedLocalStorage.getItem(FILTER_NAME) as string)

      // Reset filter if version has changed.
      if (filter.version !== FILTER_VERSION) {
        filter = getEmptyFilter()
      }
      // Dates become ISO strings when stringified, we must convert them
      // to DateTimes
      if (filter.toDate) {
        filter.toDate = DateTime.fromISO(filter.toDate, {setZone: false})
      }
      if (filter.fromDate) {
        filter.fromDate = DateTime.fromISO(filter.fromDate)
      }
      this.filter.set(filter)
      this.pFilter = filter
      this.setFilters({})
    } catch (e) {
      // Can happen and is quite common.
      this.injectedLocalStorage.setItem(FILTER_NAME, JSON.stringify(this.filter()))
    }
  }

  /**
   * Returns an array with unique members from the loan list items. E.g. for
   * offices ['Ystad', 'Lomma' ... ]
   *
   * @param property - The property of the loan list item.
   * @private
   */
  private getSet(property: string): string[] {
    return [...new Set(this.loans.map((loan: LoanListItem) => (loan as any)[property]))]
      .sort((a: string, b: string) => a.localeCompare(b))
  }

  private applyFilters(filter: FilterData): LoanListItem[] {
    const from = filter.fromDate?.toMillis()
    const to = filter.toDate?.toMillis()
    return this.loans
      .filter((loan: LoanListItem) => !from || from <= loan.renewalDate)
      .filter((loan: LoanListItem) => !to || to >= loan.renewalDate)
      .filter((loan: LoanListItem) => this.letterTypes.indexOf(loan.type) !== -1)
      .filter((loan: LoanListItem) => this.ownerTypes.indexOf(loan.ownerType) !== -1)
      .filter((loan: LoanListItem) => this.bindings.indexOf(loan.frequency) !== -1)
      .filter((loan: LoanListItem) => filter.office === 'all' || filter.office === loan.office)
      .filter((loan: LoanListItem) => filter.employee === 'all' || filter.employee === loan.responsible)
      .filter((loan: LoanListItem) => filter.productCode === 'all' || filter.productCode === loan.productCode)
      .filter((loan: LoanListItem) => {
        const fields = Object.entries(loan) // Create an array of loan object properties as key-value pairs
          .filter((val: [string, any]) => this.fieldList.indexOf(val[0]) !== -1) // Filter out unnecessary properties
          .map((val: [string, any]) => (val[1] + '').toLowerCase())
        return fields.join('').includes(filter.searchField.toLowerCase()) // Join all values and see if search string is included
      })

  }
}
